<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="categories"
          :items="categories"
          :itemHeader="categoryItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      categories: [],
      categoryItemHeader: [
        { text: "id", value: "id" },
        { text: "Title", value: "english_title" },
        { text: "Description", value: "english_description" },
        { text: "Title", value: "arabic_title" },
        { text: "Description", value: "arabic_description" },
        { text: "sequence", value: "sequence" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        this.categories = [];
        let url =
          "items/categories?fields=child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,component_type_id.title&filter[id][neq]=103";
        const data = await ApiService.get(url);
        let categories = data.data.data;
        categories.forEach((element) => {
          if (element.child_categories.length != 0) {
            this.categories.push(element);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(null);
  },
};
</script>

<style></style>
